<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-bank-transfer"
                                        style="transform: scale(1.7);"></i> Account Activity</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i>The default format is Excel but you can switch to AFB. </label>
                        <div v-if="!addingInput">
                            <div class="row mb-md-4 mt-4">
                                <div class="col-md-2"></div>
                                <div class="col-sm-12 col-md-8" style="display: flex;">

                                    <div style="display: flex;flex-direction: row;align-items: flex-start;"
                                        class="col-sm-6 col-md-6">
                                        <label class="d-inline-flex align-items-center text-nowrap">Add account
                                            activity</label>
                                        <button type="button" class="btn btn-primary ml-3" @click="addingInput = true"><i
                                                class="bx bxs-receipt" style="transform: scale(1.4);"></i></button>
                                        <label class="ml-3 d-inline-flex align-items-center text-nowrap"> Need a
                                            template?</label><button id="download" @click="generateExcelFile()"
                                            class="btn btn-primary ml-3"><i class="mdi mdi-clipboard-arrow-down-outline"
                                                style="transform: scale(1.4);"></i></button>
                                    </div>

                                </div>
                            </div>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="existingActivities" :fields="fields"
                                    responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered">
                                    <template v-slot:cell(viewButton)="data">
                                        <div class="text-center">
                                            <b-button @click="viewActivity(data)" size="sm" variant="primary"><i
                                                    style="transform: scale(1.4);" class="mdi mdi-eye"></i></b-button>
                                        </div>

                                        <b-modal v-if="data.index == selecIndex" v-model="showModal" size="lg"
                                            title="Transactions">
                                            <div v-if="selectedActivities">
                                                <!-- <div>new table starts here</div> -->
                                                <b-table style="padding: .5rem;" :items="selectedActivities"
                                                    :fields="Secondfields" responsive="sm" :per-page="BETAperPage"
                                                    :current-page="BETAcurrentPage" :sort-by.sync="BETAsortBy"
                                                    :sort-desc.sync="BETAsortDesc" :filter="BETAfilter"
                                                    :filter-included-fields="BETAfilterOn" @filtered="BETAonFiltered">
                                                </b-table>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="dataTables_paginate paging_simple_numbers ">
                                                            <ul
                                                                class="pagination pagination-rounded mb-0 justify-content-center">
                                                                <!-- pagination -->
                                                                <b-pagination v-model="BETAcurrentPage"
                                                                    :total-rows="BETArows"
                                                                    :per-page="BETAperPage"></b-pagination>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <template #modal-footer="{ ok }">
                                                <!-- Modal buttons -->
                                                <b-button @click="saveChanges" variant="primary">Ok</b-button>
                                            </template>
                                        </b-modal>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="addingInput">
                            <button @click="handleGoback" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <form @submit.prevent="preventsubmit">
                                <b-form-group class="col-md-12" label="Bank" label-for="input-bank">
                                    <multiselect v-model="newInput.bank" id="input-bank" :options="Banks"
                                        @input="selectAgencies()" />
                                </b-form-group>
                                <b-form-group class="col-md-12" label="Agency" label-for="input-agency">
                                    <multiselect v-model="newInput.agency" id="input-agency" :options="selectedAgencies"
                                        @input="selectRibs()" />
                                </b-form-group>
                                <div style="display: flex;">
                                    <b-form-group :class="AccountActivity.Currency ? `col-md-11` : `col-md-12`" label="RIB"
                                        label-for="input-rib">
                                        <multiselect v-model="AccountActivity.RIB" @input="getCurrency()" id="input-rib"
                                            :options="selectedRibs" />

                                    </b-form-group>


                                    <b-form-group v-if="AccountActivity.Currency" label="Currency"
                                        label-for="input-currency">
                                        <b-form-input disabled id="input-currency"
                                            v-model="AccountActivity.Currency"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div v-if="!data.length" style="display: flex;justify-content: space-around;">

                                    <div class="form-container mt-3">
                                        <b-form-group style="width: 50%; !important" class="text-left mt-3"
                                            label="Please select format :" label-for="Bank Statement Format">
                                            <b-form-radio v-model="format" :value=Formats[0] @change="changeFormat">{{ Formats[0]
                                            }}</b-form-radio>
                                            <b-form-radio v-model="format" :value=Formats[1] @change="changeFormat">{{ Formats[1]
                                            }}</b-form-radio>
                                            PS: The default format is Excel.
                                        </b-form-group>
                                        <h3 style="margin-bottom: 20px">Add new bank statement</h3>
                                        <span> </span>

                                        <div>
                                            <button class="file-upload-button btn-info" @click="handleFileUpload()">
                                                Import
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!!data.length" class="ribbon-box">
                                    <div class="ribbon ribbon-danger float-right" @click="handleFileUpload()"
                                        style="margin-top: 3rem!important; margin-bottom: -4rem!important; cursor: pointer">
                                        Change file
                                        <i class="mdi mdi-sync-circle ml-1" style="transform: scale(1.4);"></i>
                                    </div>
                                    <div class="table-responsive ribbon-content"
                                        style="display: flex;justify-content: space-around;">
                                        <table class="table table-striped mb-0" style="width: 97%;">
                                            <thead>
                                                <tr>
                                                    <th v-for="label in dataLabels">{{ label }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(entry, index) in data" :key="`tabledata_${index}`">
                                                    <td v-for="(cell, indexx) in entry"
                                                        :key="`celldata_${indexx}_${index}`"> {{
                                                            cell }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="mt-4 mb-3 text-center">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn btn-outline-primary btn-bordered-primary"
                                        style="width: 9rem;">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import { read, utils, write } from 'xlsx';
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from "vue-multiselect";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    data() {
        return {
            Agios: {},
            accountAgios: [],
            // codesToRemove : ['0020', '0665', '0666', '0667', '0089', '0568', '0696', '0632', '0656', '0919'],
            format: "Excel",
            selecIndex: null,
            showModal: false,
            selectedActivities: null,
            existingActivities: [],
            existingRibs: [],
            selectedAgencies: [],
            selectedRibs: [],
            Agencies: [],
            Banks: [],
            newInput: {
                bank: "",
                agency: "",
            },
            Formats: ["Excel", "AFB"],
            AccountActivity: {
                Societe: localStorage.getItem("societe").slice(1, -1),
                RIB: "",
                Balance: "",
                Currency: "",
                activity: []
            },
            dataLabels: [],
            data: [],
            listeFacture: [],
            addingInput: false,
            fileUploaded: false,


            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "RIB",
                    sortable: true,
                },
                {
                    key: "Balance",
                    sortable: true,
                },
                {
                    key: "Currency",
                    sortable: true,
                },
                {
                    key: 'viewButton', label: '',
                    sortable: false,
                    tdClass: 'view-column',
                }
            ],
            Secondfields: [
                {
                    key: "Date",
                    sortable: true,
                },
                {
                    key: "Description",
                    sortable: true,
                },
                {
                    key: "Devise",
                    sortable: true,
                },
                {
                    key: "Debit",
                    sortable: true,
                },
                {
                    key: "Credit",
                    sortable: true,
                },
            ],
            BETAtotalRows: 1,
            BETAcurrentPage: 1,
            BETAperPage: 10,
            BETApageOptions: [10, 25, 50, 100],
            BETAfilter: null,
            BETAfilterOn: [],
            BETAsortBy: "",
            BETAsortDesc: false,
        };
    },
    components: {
        Layout,
        Multiselect
    },
    async mounted() {
        await this.getBanks()
        await this.getAgencies()
        await this.getexistingRibs()
        await this.getAccounActivities()
    },
    computed: {
        rows() {
            return this.existingActivities.length;
        },
        BETArows() {
            return this.selectedActivities.length;
        },
    },

    methods: {
        isLastCharacterNumeric(str) {
            return /[0-9]$/.test(str);
        },
        convertToDate(str) {
            // Assuming the input is YYMMDD
            let year = "20" + str.substring(0, 2);
            let month = str.substring(2, 4);
            let day = str.substring(4, 6);

            return `${year}-${month}-${day}`;
        },
        swapFirstLastTwoChars(str) {
            if (str.length < 4) {
                return str; // Not enough characters to swap
            }
            let firstTwoChars = str.substring(0, 2);
            let lastTwoChars = str.substring(str.length - 2);
            let middleChars = str.substring(2, str.length - 2);

            return lastTwoChars + middleChars + firstTwoChars;
        },
        zblit(array, index, numcaract) {

            let first = array[index].slice(0, numcaract); // Get the first part of the first string

            let second = array[index].slice(numcaract); // Get the second part of the first string

            array[index] = first; // Replace the first string with the first part
            array.splice(index, 0, second); // Insert the second part at index 1
            return array;

        },
        changeFormat() {
            console.log(this.format)
            // if(this.format == "Credit/Debit"){
            //     this.dataLabels = ["Code","Currency","Compte Bancaire","Date Operation", "Date Valeur", "Libelle", "Reference", "Montant"]
            //     console.log(this.dataLabels)
            // }else{
            //     this.dataLabels = ["Code","Currency","Compte Bancaire","Date Operation", "Date Valeur", "Libelle", "Reference", "Montant"]
            //     console.log(this.dataLabels)
            // }
        },
        handleGoback() {
            this.addingInput = false
            this.selectedRibs = []
            this.selectedAgencies = []
            this.data = []
            this.newInput = {
                bank: "",
                agency: "",
            }
            this.AccountActivity = {
                Societe: localStorage.getItem("societe").slice(1, -1),
                RIB: "",
                Balance: "",
                Currency: "",
                activity: []
            }
        },
        saveChanges() {
            this.showModal = false;
        },
        viewActivity(Transactions) {
            // Implement your logic for editing the client here
            const objData = { ...Transactions.item }
            this.selectedActivities = Object.values(objData.activity) // Clone the client object to avoid modifying the original data
            this.selecIndex = Transactions.index
            this.showModal = true; // Show the modal
        },
        async getAccounActivities() {
            try {
                this.existingActivities = await axios.get(`https://finex.4help.tn/api/v1/getactivity/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
            } catch (e) {
                throw new Error('An error has occured')
            }
        },
        async getexistingRibs() {
            try {
                this.existingRibs = await axios.get(`https://finex.4help.tn/api/v1/getribss/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
            } catch (e) {
                throw new Error('An error has occured')
            }

        },
        async getCurrency() {
            const code = this.AccountActivity.RIB.slice(0, 2)
            try {
                this.AccountActivity.Currency = await axios.get(`https://restcountries.com/v3.1/alpha/${code}`).then(res => { return res.data[0].currencies[Object.keys(res.data[0].currencies)[0]].name.toString() })
            } catch (e) {
                if (e) this.AccountActivity.Currency = ""
                throw new Error('An error has occured')
            }
        },
        selectAgencies() {
            if (this.newInput.bank) {
                this.selectedAgencies = this.Agencies.filter(obj => obj.Bank === this.newInput.bank).map(obj => obj["Agency Name"])
            }
        },
        selectRibs() {
            if (this.newInput.agency) {
                this.selectedRibs = this.existingRibs.filter(obj => obj.Agency === this.newInput.agency).map(obj => obj["RIB"])
            }
        },
        handleSumbit() {
            console.log('//////////////////////////////////////////')

            if (this.newInput.agency && this.newInput.bank && this.AccountActivity.RIB) {
                try {
                    console.log('//////////////////////////////////////////')
                    console.log(this.AccountActivity)
                    axios.post(`https://finex.4help.tn/api/v1/addactivity`, this.AccountActivity).then(res => {
                        swal({
                            title: 'Success',
                            text: `${this.AccountActivity.activity.length}'s activity has been added`,
                            icon: 'success',
                        });
                        this.getAccounActivities()
                        this.addingInput = false
                        console.log(res.data)
                    })
                    // console.log("this AccountActivity.RIB ",this.AccountActivity.RIB)
                    // this.Agios.RIB=this.AccountActivity.RIB
                    // console.log(this.Agios['RIB'])
                    // this.Agios.agios=this.accountAgios
                    // console.log("this will be affected to agioss",this.accountAgios)
                    // console.log(this.Agios['agios'])
                    // axios.post(`https://finex.4help.tn/api/v1/accountagios${localStorage.getItem("societe").slice(1, -1)}`, this.Agios).then(async (res) => {
                    //     console.log(res)
                    // })
                } catch (e) {
                    swal({
                        title: 'Error',
                        text: 'Server error',
                        icon: 'error',
                    });
                }
            } else {
                console.log("gfutf")
            }
        },
        async getAgencies() {
            this.Agencies = await axios.get(`https://finex.4help.tn/api/v1/getbankagencies/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
        },
        async getBanks() {
            this.Banks = await axios.get(`https://finex.4help.tn/api/v1/getbanks/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data.map(obj => obj["Name"]) })
        },
        preventsubmit(e) {
            e.preventDefault()
        },
        async generateExcelFile() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet');

            worksheet.columns = [
                { header: 'Code', key: 'code', width: 10 },
                { header: 'Reference', key: 'reference', width: 15 },
                { header: 'Date', key: 'date', width: 10 },
                { header: 'Value Date', key: 'valueDate', width: 15 },
                { header: 'Description', key: 'description', width: 20 },
                { header: 'Debit', key: 'debit', width: 10 },
                { header: 'Credit', key: 'credit', width: 10 },
                { header: 'Balance', key: 'balance', width: 15 },
            ];

            // Sample data for the table
            const data = [
                ['001', 'Ref001', new Date(), new Date(), 'Payment', 100, 0, 100],
                ['002', 'Ref002', new Date(), new Date(), 'Receipt', 0, 200, 200],
                // ... more rows
            ];

            // Add rows to the worksheet
            data.forEach(row => {
                worksheet.addRow(row);
            });

            // Add a table that uses the data above
            worksheet.addTable({
                name: 'Transactions',
                ref: 'A1',
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleMedium2',
                    showRowStripes: true,
                },
                columns: worksheet.columns.map(col => ({ name: col.header, filterButton: true })),
                rows: data,
            });

            // Adjust each column's width to fit content
            worksheet.columns.forEach(column => {
                column.width = column.width < 20 ? 20 : column.width;
            });

            // Write to a buffer
            const buffer = await workbook.xlsx.writeBuffer();

            // Use FileSaver to save the file
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'transactions.xlsx');
        }

        ,

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        BETAonFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.BETAtotalRows = filteredItems.length;
            this.BETAcurrentPage = 1;
        },
        // async PostInvoice(data) {
        //     await axios.post("https://finex.4help.tn/api/v1/addsaleinvoice", data).then(async (res) => {
        //         if (res.status === 200) {
        //             swal({
        //                 title: 'Success',
        //                 text: `Successfully added ${data.length} invoices!`,
        //                 icon: 'success',
        //             });
        //             await this.getFactureVente()
        //             this.currentPage = 1
        //             this.data = []
        //             this.fileUploaded = false


        //         } else {
        //             swal({
        //                 title: 'Error',
        //                 text: `Server error.`,
        //                 icon: 'success',
        //             });
        //         }
        //     })
        //     this.showPopup = true;
        //     this.isPopupVisible = true;
        // },
        submitHandle() {
            this.PostInvoice(this.getSelectedData())

        },
        async handleFileUpload() {
            this.data = []
            const input = document.createElement("input");
            input.type = "file";
            if (this.format == "Excel") {
                input.accept = "xlsx , xls";
            }
            else {
                input.accept = ".txt, .afb";
            }
            input.style.display = "none";
            input.addEventListener("change", async (event) => {
                const selectedFile = event.target.files[0];
                if (selectedFile) {
                    const file = selectedFile;
                    if (file) {
                        this.fileUploaded = true
                        const readFileAsync = (file) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader()
                                reader.onload = (e) => resolve(e.target.result)
                                reader.onerror = (e) => reject(e)
                                reader.readAsBinaryString(file)
                            });
                        };
                        if (this.format == "AFB") {
                            try {
                                const textData = await readFileAsync(selectedFile)
                                // Assuming your text file has data separated by newlines
                                const lines = textData.split(/\r\n|\n/);
                                // Further process the lines if needed
                                lines[1] = "Date Valeur  Compte Bancaire  Currency  Code CIB  Libelle  Date Operation  Reference  Montant"

                                const y = lines[1].split(/\s+\s+/)
                                this.data.push(y)
                                for (let i = 2; i < lines.length - 1; i++) {
                                    const line = lines[i];
                                    let x = line.split(/\s+\s+/);
                                    x[x.length - 1] = x[x.length - 1].slice(0, -1)
                                    x = this.zblit(x, 0, 7)
                                    x = this.zblit(x, 0, 4)
                                    x = this.zblit(x, 0, 5)
                                    x = this.zblit(x, 0, 3)
                                    x = this.zblit(x, 0, 2)
                                    x = this.zblit(x, 0, 13)
                                    x = this.zblit(x, 7, 6)
                                    x.splice(2, 1)
                                    x.splice(3, 1)
                                    x.splice(4, 1)
                                    // Process each line, for example, split by a delimiter if it's CSV-like
                                    this.data.push(x);
                                }
                                //console.log(this.data)

                                // Depending on the structure of your text data,
                                // you might need to adjust the processing logic here
                                let arrayOfObjects = this.data.map((item) => ({
                                    'Date Valeur': this.convertToDate(this.swapFirstLastTwoChars(item[0])),
                                    'Compte Bancaire': item[1],
                                    'Currency': item[2],
                                    Code: item[3],
                                    'Libelle': item[4],
                                    'Date Operation': this.convertToDate(this.swapFirstLastTwoChars(item[5])),
                                    'Reference': item[6],
                                    'Montant': item[7],
                                }));
                                arrayOfObjects.shift();
                                console.log("BEFORE FILTER ", arrayOfObjects)
                                arrayOfObjects.forEach((item) => {
                                    if (!(this.isLastCharacterNumeric(item['Montant']))) {
                                        item['Montant'] = item['Montant'].slice(0, -1);
                                    }
                                });
                                //arrayOfObjects = arrayOfObjects.filter(item => !isNaN(parseFloat(item['Montant'])));
                                console.log("BEFORE FILTER ", arrayOfObjects)
                                console.log("BEGIN FILTER")
                                // arrayOfObjects = arrayOfObjects.reduce((acc, obj) => {
                                // if (this.codesToRemove.includes(obj['Code'])) {
                                //     this.accountAgios.push(obj);
                                // } else {
                                //     acc.push(obj);
                                // }
                                // return acc;
                                // }, []);

                                console.log("END FILTER")

                                console.log("AFTER FILTER ", arrayOfObjects)

                                // After processing, you can assign the data to your activity
                                 this.AccountActivity.activity = arrayOfObjects;
                                //this.AccountActivity.activity = this.getLabeledData(this.data, this.dataLabels)
                                //console.log(this.AccountActivity.activity)
                            } catch (error) {
                                console.error(error)
                                // Handle errors here
                            }
                        } else if (this.format == "Excel") {
                            try {
                                const data = await readFileAsync(file)
                                const workbook = read(data, { type: 'binary' })
                                const sheetName = workbook.SheetNames[0]
                                const sheet = workbook.Sheets[sheetName]
                                this.data = utils.sheet_to_json(sheet, { header: 1 })

                                //this.dataLabels = this.data[0]
                                this.dataLabels = this.data[0].map(column => column.trim())
                                this.data[0].push('Societe');
                                const dataModel = ["Code", "Reference", "Date", "Value Date", "Description", "Debit", "Credit", "Balance"]
                                if (JSON.stringify(this.dataLabels) !== JSON.stringify(dataModel)) {
                                    swal({
                                        title: 'Error',
                                        text: `Fields missmatch, please use our template.`,
                                        icon: 'error',
                                    })
                                    this.data = []
                                    return
                                }
                                this.data.shift()
                                this.data.map(row => {
                                    row[5] = row[5].toString()
                                    row[6] = row[6].toString()
                                    row[7] = row[7].toString()
                                    row[2] = this.excelDateToJSDate(row[2])
                                    row[3] = this.excelDateToJSDate(row[3])



                                })

                                console.log(this.data)
                                this.AccountActivity.activity = this.getLabeledData(this.data, this.dataLabels)
                            } catch (error) {
                                console.error(error)
                                // Handle errors here
                            }
                        }



                    }
                }


            });

            input.click();
            //     function excelSerialDateToJSDate(serialDate) {
            //     const excelEpoch = new Date(1899, 11, 31); // JavaScript uses 0-indexed months (December is 11)
            //     const excelEpochAsJS = excelEpoch.getTime();
            //     const millisecondsPerDay = 24 * 60 * 60 * 1000;
            //     const serialDateMilliseconds = serialDate * millisecondsPerDay;
            //     return formatAsYYYYMMDD(new Date(excelEpochAsJS + serialDateMilliseconds));
            // }

            // function formatAsYYYYMMDD(date) {
            //     const pad = (number) => number.toString().padStart(2, '0');
            //     const year = date.getFullYear();
            //     const month = pad(date.getMonth() + 1); // +1 since months are 0-indexed in JS
            //     const day = pad(date.getDate());
            //     return `${year}-${month}-${day}`;
            // }

        },
        getLabeledData(data, columns) {
            console.log(columns)
            return data.map(row => {
                const obj = {}
                columns.forEach((col, index) => {
                    obj[col] = row[index]
                })
                return obj
            });
        },
        excelDateToJSDate(excelDateSerial) {
            const excelEpoch = new Date(1899, 11, 32); // Excel's epoch starts on December 30, 1899

            // Add the number of days to the Excel epoch
            const jsDate = new Date(excelEpoch.getTime());
            jsDate.setDate(jsDate.getDate() + excelDateSerial - 1);

            // Format the date as a string "YYYY-MM-DD"
            const formattedDate = jsDate.toISOString().split('T')[0];
            return formattedDate;
        },
        processExcelData(jsonData) {
            // Process jsonData if needed
            // For example, you can filter unwanted rows, modify data, etc.
            return jsonData;
        }
    }
};
</script>

<style scoped>
.form-container {
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 97%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
}

.file-upload-button {
    color: #fff;
    padding: 1rem 4rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.file-upload-button:hover {
    background-color: #0056b3;
}

.accueil {
    text-align: center;
    padding: 4rem;
}

.accueil {
    text-align: center;
}

.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

tr:hover {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    transition: background-color 0.3s;
}</style>